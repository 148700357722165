<template>
  <div>
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table id="account-table" :dataSource="tableDataSource" height="auto">
          <p-t-column type="seq" min-width="60" title="序号" fixed="left" />
          <p-t-column field="id" min-width="220" title="申请ID" />
          <p-t-column field="company_name" min-width="336" title="企业名称">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 18
                  ? row.company_name?.substr(0, 18) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column
            field="amount"
            min-width="140"
            title="开票金额（元）"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.amount / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column field="type_desc" min-width="140" title="发票类型" />
          <p-t-column field="apply_user" min-width="140" title="申请人" />
          <p-t-column
            min-width="180"
            title="申请时间"
            field="apply_time"
            cell-render="p-date"
            sortable
          >
            <template v-slot="{ row }">
              {{
                row.apply_time &&
                  formatToDateTime(row.apply_time, "yyyy-MM-dd hh:mm:ss")
              }}
            </template>
          </p-t-column>
          <p-t-column field="express_info" min-width="160" title="快递信息" />
          <p-t-column width="80" title="操作" fixed="right">
            <template v-slot="{ row }">
              <!-- <Button type="link" @click="handleToDetail(row)">
                明细
              </Button> -->
              <Button type="link" @click="handleShowDetail(row)">
                查看
              </Button>
            </template>
          </p-t-column>
          <template #footer>
            <div class="pagination-left-slot">
              <p class="total">
                已开票金额：<span>{{ (totalAmount / 100).toFixed(2) }}</span> 元
              </p>
            </div>
          </template>
        </p-table>
      </div>
    </div>

    <!-- 明细页面 -->
    <Drawer
      class="drawer-box"
      v-model:visible="detailVisible"
      id="#serviceOrderBox"
      placement="bottom"
      height="560"
    >
      <AuditDetail :id="id"></AuditDetail>
    </Drawer>
    <!-- 发票详情页面 -->
    <Drawer
      class="drawer-box"
      v-model:visible="showVisible"
      id="#serviceOrderBox1"
      placement="bottom"
      height="600"
    >
      <span>查看</span>
      <BillDetail :row="billRow" @cancel="showVisible = false" />
    </Drawer>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
// import { useRouter } from "vue-router";
import { useAccountInvoicePageApi } from "@/apis/financial";
import { useCompanyEnumApi } from "@/apis/enums";
import { formatToDateTime } from "@wlhy-web-lib/shared";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useSearchForm from "@/hooks/UseSearchForm";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import useDebounce from "@/hooks/useDebounce";
import AuditDetail from "../AuditDetail/index";
import Drawer from "@/components/Drawer";
import BillDetail from "../BillDetail/index";

export default {
  name: "account",
  components: { Button, AuditDetail, Drawer, BillDetail },
  setup() {
    // const router = useRouter();
    const accountInvoicePage = useAccountInvoicePageApi();
    const companyEnumApi = useCompanyEnumApi();

    const visible = ref(false);
    const detailVisible = ref(false);
    const showVisible = ref(false);
    const id = ref("");
    const billRow = ref({});

    // table高度
    const tableHeight = ref(0);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });
    const totalAmount = ref("");

    let schemaList = searchHeaderOptions;
    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);
    handleSearch();

    enumeration.props.onSearch = handleSearch;

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          status: "APPROVED",
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(accountInvoicePage(data));

        totalAmount.value = res.ext.totalAmount;

        return useTableDataRecords(res);
      }
    });

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    // 审核
    const handleToAudit = row => {
      visible.value = true;
      id.value = row.id;
    };
    // 查看明细
    const handleToDetail = row => {
      detailVisible.value = true;
      id.value = row.id;
    };

    const handleShowDetail = async row => {
      showVisible.value = true;
      billRow.value = row;
    };

    return {
      id,
      visible,
      detailVisible,
      showVisible,
      totalAmount,
      tableHeight,
      tableDataSource,
      handleFormSearch,
      handleToDetail,
      handleToAudit,
      formatToDateTime,
      handleShowDetail,
      billRow,
      schema: searchHeaderOptions
    };
  }
};
</script>

<style lang="less" scoped>
.total {
  span {
    color: #007aff;
  }
}
</style>
