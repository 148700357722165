<template>
  <div>
    <div class="d-flex">
      <p class="fw-600">明细</p>
    </div>

    <p-table
      class="mt-16"
      :dataSource="tableDataSource"
      id="service-configuration-table"
    >
      <p-t-column type="seq" width="60" title="序号" fixed="left" />
      <p-t-column field="account_id" min-width="202" title="账号" />
      <p-t-column field="account_type_desc" min-width="222" title="账户类型" />
      <p-t-column
        field="money"
        width="222"
        align="right"
        title="开票金额（元）"
      >
        <template v-slot="{ row }">
          {{ row.money && (row.money / 100).toFixed(2) }}
        </template>
      </p-t-column>
      <p-t-column field="operating_time" min-width="172" title="账单时间">
        <template v-slot="{ row }">
          <span>{{ formatToDateTime(row.operating_time, "yyyy-MM-dd") }}</span>
        </template>
      </p-t-column>
    </p-table>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Table } from "@wlhy-web-components/common";
import { useAccountInvoiceBillPageApi } from "@/apis/financial";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "service-configuration",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const accountInvoiceBillPageApi = useAccountInvoiceBillPageApi();

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      }
    });

    Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          invoice_id: props.id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(accountInvoiceBillPageApi(data));
        return useTableDataRecords(res);
      }
    });

    return {
      tableDataSource,
      formatToDateTime
    };
  }
};
</script>
