<template>
  <div>
    <div class="detail">
      <div class="little-title">开票信息</div>
      <div>发票抬头：{{ detailInfo.buyer_name || detailInfo.name }}</div>
      <div>
        纳税人识别号：{{ detailInfo.buyer_tax_number || detailInfo.tax_number }}
      </div>
      <div>开户银行名称：{{ detailInfo.buyer_bank }}</div>
      <div>开户账号：{{ detailInfo.buyer_bank_account }}</div>
      <div>
        企业注册地址：{{ detailInfo.province }}{{ detailInfo.city
        }}{{ detailInfo.area }}{{ detailInfo.buyer_address }}
      </div>
      <div>企业注册电话：{{ detailInfo.buyer_phone }}</div>
      <div class="little-title">邮寄地址</div>
      <div>地址：{{ detailInfo.exp_address }}</div>
      <div>收货人：{{ detailInfo.exp_contact }}</div>
      <div>联系方式：{{ detailInfo.exp_contact_phone }}</div>
      <div>企业名称：{{ detailInfo.exp_company_name }}</div>
    </div>
    <div class="text-center pt-16">
      <Button type="primary" @click="$emit('cancel')">关闭</Button>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import { Button } from "ant-design-vue";

export default {
  name: "BillDetail",
  components: { Button },
  props: {
    row: { type: Object, default: () => {} }
  },
  setup(props) {
    const detailInfo = ref({});
    const addressInfo = ref({});

    const getInfo = async () => {
      detailInfo.value = props.row.ext_json;
    };
    onMounted(() => {
      getInfo();
    });
    return {
      detailInfo,
      addressInfo,
      getInfo
    };
  }
};
</script>

<style lang="less" scoped>
.detail {
  background: #f8f9fb;
  padding: 16px 28px;
  margin-top: 17px;
  .little-title::before {
    width: 4px;
    height: 16px;
    background: #007aff;
    content: "";
    position: absolute;
    margin-left: -10px;
  }
  div {
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
