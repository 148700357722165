// import * as rule from "@wlhy-web-lib/rule/esm";

export const getInvoiceRules = () => {
  const rules = {
    company_name: [
      {
        required: true,
        trigger: "blur",
        message: "快递公司不能为空"
      }
    ],
    order_no: [
      {
        required: true,
        trigger: "blur",
        message: "快递单号不能为空"
      }
    ]
  };

  return rules;
};
