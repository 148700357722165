<template>
  <div>
    <xModal
      title="填写快递信息"
      :visible="visible"
      :w="500"
      :footer="null"
      @handleCancel="handleCancel"
    >
      <template #content>
        <p class="text_note mb-16">填写快递信息并确定，即通过审核</p>
        <Form
          ref="formRef"
          :model="formState"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rules="rules"
          class="form-box"
        >
          <!-- 快递公司 -->
          <FormItem label="快递公司" name="company_name">
            <a-input
              v-model:value="formState.company_name"
              placeholder="请输入快递公司"
              style="width:320px;"
            />
          </FormItem>
          <!-- 快递单号 -->
          <FormItem label="快递单号" name="order_no">
            <a-input
              v-model:value="formState.order_no"
              placeholder="请输入快递单号"
              style="width:320px;"
            />
          </FormItem>

          <FormItem class="pt-24" :wrapper-col="{ span: 17, offset: 7 }">
            <a-button @click="handleCancel">取消</a-button>
            <a-button
              class="ml-16"
              type="primary"
              @click="handleSubmit"
              :loading="isLoading"
              >确定</a-button
            >
          </FormItem>
        </Form>
      </template>
    </xModal>
  </div>
</template>

<script>
import { ref } from "vue";
import { Form, message } from "ant-design-vue";
import xModal from "@/components/xModal";
import { getInvoiceRules } from "./config";
import $await from "@/utils/await";
import { useAccountInvoiceAuditApi } from "@/apis/financial";

export default {
  props: {
    id: { type: String, default: "" },
    visible: { type: Boolean, default: false }
  },
  components: {
    xModal,
    Form,
    FormItem: Form.Item
  },
  setup(props, ctx) {
    const accountInvoiceAuditApi = useAccountInvoiceAuditApi();
    const isLoading = ref(false);
    const formRef = ref();

    // 表单信息
    const formState = ref({
      company_name: "",
      order_no: ""
    });

    const rules = getInvoiceRules(formState);

    // 提交表单
    const handleSubmit = () => {
      const data = {
        id: props.id,
        express_info: `${formState.value.company_name} ${formState.value.order_no}`
      };
      formRef.value
        .validate()
        .then(async () => {
          isLoading.value = true;
          const [, res] = await $await(accountInvoiceAuditApi(data));
          if (res) {
            isLoading.value = false;
            message.success("审核完成！");
            handleCancel();
            ctx.emit("getData");
          } else {
            isLoading.value = false;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    };

    // 取消
    const handleCancel = () => {
      ctx.emit("cancel");
    };
    return {
      formRef,
      formState,
      isLoading,
      rules,
      handleCancel,
      labelCol: { span: 5 },
      wrapperCol: { span: 19 },
      handleSubmit
    };
  }
};
</script>

<style lang="less" scoped>
.clude {
  position: relative;
  .icon {
    position: absolute;
    top: 12px;
    right: 36px;
  }
}
.tips {
  width: 204px;
  font-size: 12px;
  display: block;
}
.pop-content {
  width: 280px;
  p {
    margin-bottom: 16px;
  }
}
</style>
<style lang="less">
.width-shot-option {
  width: 74px !important;
  min-width: 74px !important;
}
</style>
