<template>
  <div class="page-main-box invoice-box">
    <Tabs v-model:activeKey="activeKey" :animated="false" :tabBarGutter="48">
      <TabPane key="PENDING" tab="待审核">
        <ToAudit v-if="activeKey === 'PENDING'" />
      </TabPane>
      <TabPane key="APPROVED" tab="已通过">
        <Passed v-if="activeKey === 'APPROVED'" />
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { ref } from "vue";
import { Tabs } from "ant-design-vue";
import ToAudit from "./components/ToAudit/index";
import Passed from "./components/Passed/index";
// import Reject from "./components/Reject/index";

export default {
  name: "account",
  components: { Tabs, TabPane: Tabs.TabPane, ToAudit, Passed },
  setup() {
    return {
      activeKey: ref("PENDING")
    };
  }
};
</script>

<style lang="less" scoped></style>
<style lang="less">
.invoice-box {
  .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-nav-scroll {
    background: #fff;
    margin-bottom: 2px;
  }
  .ant-tabs-tab {
    color: #808080;
    padding: 12px 0;
    &:nth-child(1) {
      margin-left: 40px;
    }
  }
  .ant-tabs-tab-active {
    color: #007aff;
  }
}
</style>
